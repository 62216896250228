
import { defineComponent, ref, computed } from '@vue/composition-api'
import { mdiEarth } from '@mdi/js'

import CommonFlagIcon from '@/components/common/CommonFlagIcon.vue'

import { useNotify } from '@/store'

import { resolveCountry } from '@/plugins/i18n'

import { translationsApi } from '@/api'

import { ValidationForm } from '@/utils/types/validation'
import { isRequired } from '@/utils/validation'

export default defineComponent({
  name: 'AdminAddTranslation',
  components: {
    CommonFlagIcon,
  },
  setup(_, { root, emit }) {
    const { updateTranslation, isLoading } = translationsApi.useUpdateTranslation()

    const { addNotification } = useNotify()

    const addTranslationForm = ref<ValidationForm | null>(null)

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const keyInput = ref('')

    const translationInputs = ref<{ [key: string]: string }>({})

    const addTranslation = () => {
      if (addTranslationForm.value?.validate()) {
        const promises = Object.entries(translationInputs.value).map(([locale, value]) =>
          updateTranslation({ key: keyInput.value, value, locale })
        )

        Promise.all(promises)
          .then(() => {
            addNotification({
              type: 'success',
              text: root.$t('translations.add.success', {
                key: keyInput.value,
              }) as string,
            })

            emit('translation-added')
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('translations.add.error', {
              key: keyInput.value,
            }) as string

            throw error
          })
      }
    }

    return {
      icons: { mdiEarth },
      addTranslationForm,
      isRequired,
      isLoading,
      keyInput,
      translationInputs,
      addTranslation,
      implementedLocales,
      resolveCountry,
    }
  },
})
