var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('translations.add.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "addTranslationForm",
    attrs: {
      "id": "add-translation-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addTranslation.apply(null, arguments);
      }
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.isRequired(_vm.keyInput, _vm.$t('form.field.translationKey'))],
      "label": _vm.$t('form.field.translationKey'),
      "aria-required": "",
      "outlined": ""
    },
    model: {
      value: _vm.keyInput,
      callback: function callback($$v) {
        _vm.keyInput = $$v;
      },
      expression: "keyInput"
    }
  })], 1)], 1), _vm._l(_vm.implementedLocales, function (locale) {
    return _c('v-row', {
      key: locale,
      attrs: {
        "dense": ""
      }
    }, [_c('v-col', [_c('v-text-field', {
      attrs: {
        "label": _vm.$t("locale.".concat(locale)),
        "rules": [_vm.isRequired(_vm.translationInputs[locale], _vm.$t("locale.".concat(locale)))],
        "aria-required": "",
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "prepend-inner",
        fn: function fn() {
          return [_vm.resolveCountry(locale) !== '' ? _c('CommonFlagIcon', {
            staticClass: "mr-2",
            attrs: {
              "locale": _vm.resolveCountry(locale)
            }
          }) : _vm._e(), _vm.resolveCountry(locale) === '' ? _c('v-icon', {
            staticClass: "mr-2"
          }, [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.translationInputs[locale],
        callback: function callback($$v) {
          _vm.$set(_vm.translationInputs, locale, $$v);
        },
        expression: "translationInputs[locale]"
      }
    })], 1)], 1);
  })], 2)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('form.action.cancel')))]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "type": "submit",
      "form": "add-translation-form",
      "loading": _vm.isLoading
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.addTranslation')) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }